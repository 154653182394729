import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import Video from '../Video'
import { trackSelectPromotion } from '../../../lib/ga4'
import ButtonCTA from '../Button/button_cta'
import Link from '../../Link/index'

const HeaderCopyHeading = styled.div`
  /* width: 558px;
  height: 76px; */
  object-fit: contain;
  font-family: Objectivity;
  font-weight: ${(props) => (props.HeaderFontWeight ? `${props.HeaderFontWeight};` : '400')};
  font-family: ${(props) => (props.HeaderFont ? `${props.HeaderFont};` : 'Objectivity')};
  font-size: ${(props) => (props.HeaderFontSize ? ` ${props.HeaderFontSize}px;` : '52px')};
  font-style: ${(props) => (props.HeaderFontStyle ? `${props.HeaderFontStyle};` : 'normal')};
  font-stretch: normal;
  line-height: 1.15;
  letter-spacing: -2px;
  ${(props) => (props.color ? `color: ${props.color};` : '')}
  @media (min-width : 1026px) {
    ${(props) => (props.textPosition === 'custom-top' ? `text-align: start;` : '')};
    ${(props) => (props.textPosition === 'custom-top' ? `max-width: 500px;line-height: 1.1;` : '')};
  }
  @media (max-width : 1025px) {
    font-size: 52px;
    width: 100%;
  }
  @media (max-width: 768px) {
    margin: ${(props) => props.hide ? '20px;' : '0px;'};
    font-size: 36px;
    text-align: center;
    width: 100%;
  }
`
const HeaderCopySubHeading = styled.div`
  font-size: ${(props) => (props.BodyFontSize ? `${props.BodyFontSize}px;` : '21px;')};
  font-family: ${(props) => (props.BodyFont ? `${props.BodyFont};` : 'Objectivity;')};
  font-style: ${(props) => (props.BodyFontStyle ? `${props.BodyFontStyle};` : 'normal;')};
  font-weight: ${(props) => (props.BodyFontWeight ? `${props.BodyFontWeight};` : '400')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.21px;
  padding: 20px 0;
  ${(props) => (props.color ? `color: ${props.color};` : '')}
  @media (max-width : 1025px) {
    font-size: ${(props) => (props.BodyFontSize === 75 ? `64px;` : '18px;')}
    letter-spacing: -0.16px;
    padding-bottom: 0;
    margin: 0 20px;
  }
  @media (max-width: 768px) {
    opacity: 0.85;
    font-size: ${(props) => (props.BodyFontSize === 75 ? `64px;` : '16px;')}
    letter-spacing: -0.12px;
    line-height: 1.4;
    text-align: center;
    margin: 0 16px;
    ${(props) => (props.hideCopy ? `display: none;` : '')};
    padding: 8px 0;
  }
  @media (min-width : 1026px) {
    ${(props) => (props.textPosition === 'custom-top' ? `text-align: start;` : 'text-align: center;')}
    ${(props) => (props.TextAlign ? `text-align: ${props.TextAlign}` : 'text-align: center;')};
  }
`
const SectionContainerTop = styled.div`
  background-color: ${(props) => props.color};
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  ${(props) =>
    props.HeightPercentage
      ? `height: calc(${props.HeightPercentage}vh - 96px);`
      : 'height: calc(100vh - 96px);'}
  @media (max-width: 768px) {
    ${(props) => (props.mobilePadding ? 'padding: 32px 0px;' : '')};
    height: 100%;
    flex-direction: ${(props) => (props.reverseMobile ? 'column-reverse' : 'column')};
  }
  @media (min-width: 1024px) {
    ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
    ${(props) => (props.negativeMargin ? 'height: calc(75vh - 126px);' : '')}
    ${(props) => (props.maxWidth ? 'max-width: 1200px; margin: 0 auto;' : '')}
  }

  ol {
    margin: 0px;
    padding: 5%;
  }
  li {
    list-style: decimal;
  }
`

const AboveTheHeader = styled.div`
  object-fit: contain;
  font-weight: 400;
  font-family: 'SackersGothicStd-Heavy';
  font-size: 24px;
  ${(props) => (props.color ? `color: ${props.color};` : '')}
  text-align: center;
  ${(props) => (props.TextAlign === 'start' ? `text-align: start;` : '')}

  padding: 32px 0;
  @media (max-width: 768px) {
    font-size: 16px;
    padding: 24px 0;
  }
`
const CopyWidthContainer = styled.div`
  z-index: 9;
  @media (min-width: 1025px) {
    overflow: hidden;
    max-width: 600px;
    ${(props) => (props.textPosition ? `margin: 25px;` : 'margin: 0 auto;')};
    ${(props) => (props.textPosition === 'custom-top' ? `height: 100%;margin: 72px;` : '')};
    ${(props) => (props.textPosition === 'custom-top' ? `position: relative;` : '')};
  }
  ${(props) =>
    props.elements === true
      ? `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    `
      : ''}
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 16px;
    padding-bottom: 36px
  }
`
const TransparentButton = styled.button`
  font-family: Objectivity;
  border-radius: 27px;
  ${(props) => props.BorderColor ? `border: solid 1px ${props.BorderColor};` : 'border: solid 1px white;'};
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 49px;
  letter-spacing: 0.45px;
  text-align: center;
  color: ${(props) => props.textColor ? `${props.textColor}` : 'white;'};
  background: ${(props) => props.BorderColor === '#FFAC42' ? `#FFAC42` : 'transparent;'};;
  height: 49px;
  padding: 0 16px;
  outline: none;
  cursor: pointer;
  width: 300px;
  @media (max-width: 1024px) {
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    font-size: 10px;
    height: 42px;
    line-height: 42px;
  }
  @media (min-width: 1025px) {
    ${(props) => (props.noDesktop ? 'display: none;' : '')}
  }
  ${(props) => (props.center ? 'margin: 0 auto;' : '')}

  transition: all 0.2s;

  &:hover {
    background-color: ${(props) => props.HoverButtonColor ? `${props.HoverButtonColor}` : 'rgba(var(--dark-blue-grey), 0.1)'};
    color: ${(props) => props.textColor ? `${props.textColor}` : 'white;'};
    border-color: ${(props) => props.BorderColor ? `${props.BorderColor}` : `transparent`};
  }
  &:active {
    background-color: ${(props) => props.ActiveButtonColor ? `${props.ActiveButtonColor}` : 'transparent'};
    color: ${(props) => props.textColor ? `${props.textColor}` : 'white;'};
    border-color: ${(props) => props.BorderColor ? `${props.BorderColor}` : `white`};
  }
`
const HeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }

  img {
    opacity: 1 !important; /* Override the default opacity */
  }
`

const TextBackgroundImage = styled(GatsbyImage)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }

  img {
    opacity: 1 !important; /* Override the default opacity */
  }
`

const StaticHeaderImage = styled.img`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    height: calc(100vh - 110px);
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }
  object-fit: cover;
`
const HeaderVideo = styled(Video)`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;height: calc(100vh - 110px);' : '')}
  }
`
const SectionCopy = styled.div`
  position: relative;
  width: ${(props) => (props.short ? '45%;' : '50%;')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: rgb(var(--${(props) => props.color}));

  @media (min-width: 1026px) {
    ${({ school }) => school && `align-items: flex-start; text-align: left; padding-left: 10%;`}
    ${(props) => (props.textPosition === 'custom-top' ? `justify-content: start;` : 'justify-content: center;')}
  }
  @media (max-width: 1025px) {
    width: 100%;

    text-align: left;
    z-index: 2;
    ${(props) => (props.left ? 'align-items: flex-start;' : '')};
    ${(props) => (props.lower ? 'padding: 0 32px;' : '')};
    ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}
    ${(props) =>
    props.center ? 'text-align: center; align-items: center;justify-content: center;' : ''}
    ${({ trunk }) => trunk && `align-items: center;justify-content: center; text-align: center; `}
    ${(props) => (props.mobileAbsolute ? 'position: absolute; top: 22px; padding-top: 8px;' : '')}
  }
  @media (max-width: 768px) {
    ${(props) => props.hideCopy && 'height : 15vh;'}
    ${(props) => props.hide && 'display: none;'}
    height: ${(props) => (props.mHeight ? `${props.mHeight}vh;` : '')};
  }
  @media (max-width: 320px) {
    ${(props) => props.hideCopy && 'height : 15vh;'}
  }
`


// TextBackgroundImage

const HalfBanner = ({ data }) => {
  let fontFamily
  console.log(data)
  return (
    <Link
      to={data.to}
      noLink={data.to === null}
    >
      <link
        href="https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap"
        rel="stylesheet"
      />
      <SectionContainerTop
        color={data.BackgroundColor}
        reverseMobile={data.MImageOn === 'Top'}
        reverse={data.DImageOn === 'Left'}
        HeightPercentage={data.HeightPercentage}
        hide={data.HideMediaOnMobile}
      >
        <SectionCopy center={true} textPosition={data.Heading === "BUNDLE & SAVE" && 'custom-top'} hideCopy={data?.BodyAndCopyHide}>
          {
            data.TextBackgroundImage && (
              <TextBackgroundImage
                alt={data.TextBackgroundImage?.alternativeText || data.TextBackgroundImage?.name}
                image={getImage(data.TextBackgroundImage?.file?.childImageSharp?.gatsbyImageData)}
                style={{ objectFit: 'contain' }}
              />
            )
          }

          <CopyWidthContainer style={{ textAlign: 'center' }} textPosition={data.Heading === "BUNDLE & SAVE" && 'custom-top'}>
            {data?.aboveTheHeader && <AboveTheHeader TextAlign={data.TextAlign} color={data.TextColor}>{data?.aboveTheHeader}</AboveTheHeader>}
            {data.TitleImage ? (
              <HeaderCopyHeading large={true} color="white" >
                <img
                  loading="lazy"
                  style={{ width: '1000px', margin: '0px' }}
                  src={data.TitleImage?.file?.publicURL}
                  alt={data.TitleImage?.alternativeText || data.TitleImage?.name}
                />
              </HeaderCopyHeading>
            ) : (
              <HeaderCopyHeading
                selectedFontFamily={fontFamily}
                hideCopy={data?.BodyAndCopyHide}
                large={true}
                color={data.TextColor}
                HeaderFontWeight={data.HeaderFontWeight}
                HeaderFontSize={data.HeaderFontSize}
                HeaderFontStyle={data.HeaderFontStyle}
                HeaderFont={data.HeaderFont}
                textPosition={data.Heading === "BUNDLE & SAVE" && 'custom-top'}
                hide={data.HideMediaOnMobile}
              >
                {data.Heading}
              </HeaderCopyHeading>
            )}
            {data.Body && (
              <HeaderCopySubHeading
                TextAlign={data.TextAlign}
                hideCopy={data?.BodyAndCopyHide}
                color={data.TextColor}
                BodyFontWeight={data.BodyFontWeight}
                BodyFontStyle={data.BodyFontStyle}
                BodyFontSize={data.BodyFontSize}
                BodyFont={data.BodyFont}
                HeaderFontSize={data.HeaderFontSize}
                textPosition={data.Heading === "BUNDLE & SAVE" && 'custom-top'}            >
                <span dangerouslySetInnerHTML={{ __html: data.Body }} />
              </HeaderCopySubHeading>
            )}
            {data?.Button && (
              <div style={{ marginTop: '1em' }}>
                <ButtonCTA TextAlign={data.TextAlign} style={{ padding: '0 48px', margin: '12px', position: 'absolute' }} data={data.Button} noLink={data?.to ? true : false} hideButton={data?.BodyAndCopyHide} textPosition={data.Heading === "BUNDLE & SAVE" && 'custom-top'} onClick={() => { trackSelectPromotion({ creative_name: data.Heading, promotion_id: data.id }) }}>
                {' '}
                {data.Button.ButtonCopy}{' '}
              </ButtonCTA>

              </div>
            )}
            {data?.CTA && (
              <Link to={data.to}>
                <div style={{ marginTop: '1em' }}>
                  <TransparentButton style={{ padding: '0 48px' }} onClick={() => { trackSelectPromotion({ creative_name: data.Heading, promotion_id: data.id }) }}> {data.CTA} </TransparentButton>
                </div>{' '}
              </Link>
            )}
          </CopyWidthContainer>
        </SectionCopy>
        <SectionCopy hide={data.HideMediaOnMobile} mHeight={data.MHeight}>
          {
            data.HalfScreenImage?.isStatic ? (
              <StaticHeaderImage src={data.HalfScreenImage?.url} />
            ) : (
              data.HalfScreenImage?.mime?.includes('video') ? (
                <HeaderVideo
                  alt={data.HalfScreenImage?.alternativeText || data.HalfScreenImage?.name}
                  src={data.HalfScreenImage?.file.publicURL}
                  mobileSrc={data.MobileHalfScreenImage?.file.publicURL}
                  desktopSrc={data.HalfScreenImage?.file.publicURL}
                  margin={data.Heading === "BUNDLE & SAVE" && 'custom-top'}
                  loading='eager'
                />
              ) : (
                <HeaderImage
                  alt={data.HalfScreenImage?.alternativeText || data.HalfScreenImage?.name}
                  image={withArtDirection(
                    getImage(data.HalfScreenImage?.file?.childImageSharp?.gatsbyImageData),
                    [
                      {
                        media: '(max-width: 1024px)',
                        image: getImage(
                          data.MobileHalfScreenImage?.file?.childImageSharp?.gatsbyImageDataMobile ? data.MobileHalfScreenImage?.file?.childImageSharp?.gatsbyImageDataMobile : data.HalfScreenImage?.file?.childImageSharp?.gatsbyImageDataMobile
                        ),
                      },
                    ]
                  )}
                  style={{ objectFit: 'contain' }}
                />
              )
            )}
        </SectionCopy>
      </SectionContainerTop>
    </Link>
  )
}

export default HalfBanner
